import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { asText } from '@prismicio/client';

import Home from './Home';
import { TITLESUFFIX, URLROOT } from './config/constant';

import './scss/main.scss';

function App() {
  const [homepage, state] = usePrismicDocumentByUID('home_page', 'home');
  if (state.state !== 'loaded') return (<div>Loading...</div>);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{TITLESUFFIX}</title>
        <link rel="canonical" href={`${URLROOT}/`} />

        <meta property="og:title" content={TITLESUFFIX} />
        <meta property="og:url" content={`${URLROOT}/`} />
        <meta property="og:image" content={homepage.data.meta_image.url} />
        <meta property="og:description" content={asText(homepage.data.description)} />

        <meta name="twitter:title" content={TITLESUFFIX} />
        <meta name="twitter:image" content={homepage.data.meta_image.url} />
        <meta name="twitter:card" content={`${URLROOT}/`} />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            element={<Home />}
            path="/"
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
