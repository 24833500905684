/* eslint-disable jsx-a11y/control-has-associated-label */
import { PrismicRichText, PrismicText, usePrismicDocumentByID } from '@prismicio/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { asText } from '@prismicio/client';

export default function StaffBio({ staffId, noHeadshot }) {
  const [staff, state] = usePrismicDocumentByID(staffId);
  const [show, setShow] = useState(false);
  if (state.state !== 'loaded') return (<div>Loading...</div>);

  return (
    <div className="bio">
      <div className="bio">
        {staff.data.headshot && !noHeadshot
          ? (
            <button type="button" onClick={() => setShow(true)}>
              <img src={`${staff.data.headshot.url}&fit=crop&w=250&h=250`} alt={asText(staff.data.name)} />
            </button>
          ) : null}
        <div>
          <button type="button" onClick={() => setShow(true)}>
            <div className="staff-name"><PrismicText field={staff.data.name} /></div>
            <PrismicText field={staff.data.job_title} />
          </button>
        </div>
        <Modal show={show} onHide={() => setShow(false)} animation={false} className="bio-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              <PrismicText field={staff.data.name} />
              {', '}
              <PrismicText field={staff.data.job_title} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {staff.data.headshot && !noHeadshot
              ? (
                <img src={`${staff.data.headshot.url}&fit=crop&w=250&h=250`} alt={asText(staff.data.name)} />
              ) : null}
            {staff.data.bio !== undefined
              ? <PrismicRichText field={staff.data.bio} />
              : null}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
StaffBio.propTypes = {
  staffId: PropTypes.string.isRequired,
  noHeadshot: PropTypes.bool,
};

StaffBio.defaultProps = {
  noHeadshot: false,
};
