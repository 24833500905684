/* eslint-disable import/prefer-default-export */
export const APIENDPOINT = 'https://rushservices.prismic.io/api/v2';
export const URLROOT = 'https://www.rush.services';
export const TITLESUFFIX = 'Rush Information Services';

export const PRODUCTS = {
  'Most Common Orders': {
    AR: 'Air Resources Search',
    CO: 'Certificate of Occupancy',
    EA: 'ECB Address Search',
    ED: 'ECB-DOB Penalty Search',
    ER: 'Emergency Repair Search',
    FC: 'Fire Violation Search',
    FH: 'Flood Hazard Search',
    FO: 'Fuel Search',
    HB: 'Housing and Building Search',
    HE: 'Health Search',
    HI: 'Highway Violations Search',
    LM: 'Landmark Search',
    OP: 'Open Permit and Alteration Search',
    PA: 'Lien Payoff Letter',
    RC: 'Emergency Repair Contin',
    SF: 'Survey Find (Locate)',
    ST: 'Street Report Search',
    SV: 'Survey Inspection',
    SW: 'Sewer Search',
  },
  'Municipal Orders': {
    AA: 'Certificate of Application for Authority',
    AC: 'Assessors Card',
    AIR_RIGHTS: 'Air Rights',
    AO: 'Articles of Organization',
    AS: 'Assessors Letter',
    BP: 'Building Plans',
    CA: 'Certified Assessment',
    CW: 'Criminal Warrant',
    DHCR: 'Housing Renewal (DHCR)',
    EP: 'Emergency Repair Payoff Letter',
    EV: 'Electrical Violation Search',
    FTL: 'County Federal Tax Lien Search',
    IR: 'Inrem List',
    JC: 'Judgment Copy',
    JP: 'Judgment Payoff',
    LS: 'Litigation Search',
    MD: 'Multiple Dwelling Registration Card',
    PC: 'Property Card',
    SN: 'Lien Letter of Satisfaction',
    US: 'Underground Storage Tank',
    VS: 'Vault Search',
    WF: 'Wetlands (Freshwater) Search',
    WT: 'Wetlands (Tidal) search',
    ZR: 'Zoning Classification Search',
  },
  'Tax Orders': {
    AV: 'Tax Assessed Valuation',
    BASE_LOT: 'Base Lot Search',
    COMN_LOT: 'Common Lot Search',
    CONDO: 'Condo Search',
    DROP_LOT: 'Drop Lot Search',
    EASE: 'Easement Search',
    MR: 'Special Meter Reading',
    REUC: 'REUC Tax Search',
    SUBIT: 'Subiterrnean Search',
    TB: 'Tax Bill',
    TC: 'Tax Continuation',
    TH: 'Tax History',
    TM: 'Tax Map',
    TP: 'Tax Payment',
    TX_LIEN: 'Tax Lien PayOff',
    TX: 'Tax Search',
    WB: 'Water Bill',
    WC: 'Condo Water and Sewer Search',
    WH: 'Water History',
    WP: 'Water Payment',
  },
  'Examination and Name Orders': {
    BK: 'Bankruptcy Search',
    CG: 'Certificate of Good Standing',
    CI: 'Certificate of Incorporation',
    CX: 'Court Filing',
    EB: 'ECB Name Search',
    FT: 'Franchise Tax',
    GX: 'Foreclosure Gap Contin',
    HX: 'Chain of Title',
    JX: 'Judgment Search',
    LX: 'Lis Pendens Copy',
    MS: 'Open Mortgage Search',
    NX: 'Notice of Default Filing',
    PN: 'Parking Violation Name Search',
    PS: 'Patriot Search',
    UC: 'State UCC/FTL Search',
    UX: 'UCC Search (County)',
    XA: 'Attorney Search 10 Year',
    XB: 'Business Certificate Search',
    XD: 'Last Owner-Deed Search',
    XE: 'Foreclosure Search',
    XF: 'Full Search',
    XG: 'Grantee-Grantor Property Search',
    XH: 'Standard Copies',
    XJ: 'Lien Search',
    XM: 'Mortgage and Deed Search',
    XN: 'Grantee-Grantor Name Search',
    XP: 'COOP Search',
    XR: 'ReIssue Search',
    XS: 'Short Search (10 Year)',
    XT: 'Examination Continuation',
    XU: 'Examination Update',
    XW: 'Surrogate Search',
    XY: 'Residential Refinance Search-15 Year',
  },
};
