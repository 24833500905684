import React, { useState } from 'react';
import {
  Modal, Button,
} from 'react-bootstrap';

function Contact() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="contact">
      <Button onClick={handleShow}>Contact Us</Button>
      <Modal show={show} onHide={handleClose} className="contact-modal">
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <form action="https://usebasin.com/f/f284ff1d2a90" method="POST">
          <Modal.Body>
            <div>
              <label htmlFor="email-address">
                Your Email
                <input type="email" id="email" name="email" required />
              </label>
              <label htmlFor="name">
                Name
                <input id="name" type="text" name="name" required />
              </label>
              <label htmlFor="message">
                Message
                <textarea id="message" name="message" required />
              </label>
              <input type="hidden" name="message2" id="message2" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Send Message
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default Contact;
