import { PrismicRichText, PrismicText, usePrismicDocumentByUID } from '@prismicio/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { TITLESUFFIX } from './config/constant';
import Contact from './Contact';
import Staff from './Staff';
import Order from './Order';

function Home() {
  const [homepage, state] = usePrismicDocumentByUID('home_page', 'home');
  if (state.state !== 'loaded') return (<div>Loading...</div>);
  const red = homepage.data.logo_background.substr(1, 6);
  const blue = '575481';
  const homeStyle = {
    backgroundImage: `url("${homepage.data.main_banner_image.url}&gam=-50&blend=${red}&blend-alpha=20")`,
  };
  const logoFillStyle = {
    backgroundColor: homepage.data.logo_background,
  };
  const aboutImage = {
    backgroundImage: `url("${homepage.data.about_image.url}&duotone=666666,575481&duotone-alpha=40")`,
  };
  const aboutStyle = {
    backgroundColor: `#${blue}`,
  };
  const serviceImage = {
    backgroundImage: `url("${homepage.data.service_background.url}&fit=crop&h=325&w=1400&crop=top")`,
  };
  return (
    <div className="home">
      <div className="banner-background" style={homeStyle}>
        <div className="banner-content">
          <div className="banner-inverse-logo-row">
            <div className="banner-logo-fill" style={logoFillStyle}>&nbsp;</div>
            <div className="banner-logo">
              <img src={`${homepage.data.logo_strip.url}&w=600`} alt={TITLESUFFIX} />
            </div>
            <div className="banner-logo-fill" style={logoFillStyle}>&nbsp;</div>
          </div>
          <Container fluid>
            <h2><PrismicText field={homepage.data.tag_line} /></h2>
          </Container>
        </div>
      </div>
      <div className="services" style={serviceImage}>
        <Order />
        <Container>
          <Row>
            {homepage.data.services.map((service, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col md={4} key={i} className="service-list">
                <img src={`${service.icon.url}&w=100`} alt="icon" />
                <PrismicRichText field={service.service_list} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className="about" style={aboutStyle}>
        <Row>
          <Col md={6} style={aboutImage} className="about-image d-none d-md-block ">&nbsp;</Col>
          <Col md={6} lg={5} xl={4} className="about-content">
            <PrismicRichText field={homepage.data.about_teaser} />
            <Contact />
            <PrismicRichText field={homepage.data.address} />
          </Col>
        </Row>
      </div>
      <Staff />
    </div>
  );
}
export default Home;
