import { PrismicText, usePrismicDocumentByUID } from '@prismicio/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StaffBio from './StaffBio';

export default function Staff() {
  const [staffList, state] = usePrismicDocumentByUID('staff_list', 'staff-list');
  if (state.state !== 'loaded') return (<div>Loading...</div>);
  // console.log([staffList, state]);
  return (
    <div className="team">
      <Container>
        <h2><PrismicText field={staffList.data.title} /></h2>
        <Row>
          {staffList.data.staffgroup.map((staff) => (
            <Col lg={4} md={4} xs={4} className="staff-col" key={staff.staff.id}>
              <StaffBio
                staffId={staff.staff.id}
              />
            </Col>
          ))}
        </Row>
        {staffList.data.staffgroup2.map((staff) => (
          <div key={staff.staff2.id} className="staff2-row">
            <StaffBio
              staffId={staff.staff2.id}
              noHeadshot
            />
          </div>
        ))}
      </Container>

    </div>

  );
}

/*
import {
  Container,
  Row, Col,
} from 'react-bootstrap';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';

import { APIENDPOINT } from './config/constant';
import StaffBio from './StaffBio';

class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffList: {
        title: [],
        staffgroup: [],
        staffgroup2: [],
      },
    };
  }

  componentDidMount() {
    const apiEndpoint = APIENDPOINT;
    Prismic.api(apiEndpoint).then((api) => {
      api.query(Prismic.Predicates.at('document.type', 'staff_list')).then((response) => {
        if (response) {
          // console.log(response.results[0].data);
          if (response.results.length > 0) {
            this.setState({
              staffList: response.results[0].data,
            });
          }
        }
      });
    });
  }

  render() {
    const { staffList } = this.state;
    // console.log(staffList);
    return (
      <div className="team">
        <Container>
          <h2>{RichText.asText(staffList.title)}</h2>
          <Row>
            {staffList.staffgroup.map((staff, i) => (
              <Col lg={4} md={4} xs={4} className="staff-col" key={i}>
                <StaffBio
                  staffId={staff.staff.id}
                />
              </Col>
            ))}
          </Row>
          {staffList.staffgroup2.map((staff, i) => (
            <div key={i} className="staff2-row">
              <StaffBio
                staffId={staff.staff2.id}
                noHeadshot
              />
            </div>
          ))}
        </Container>

      </div>
    );
  }
}

export default Staff;
*/
