/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  Modal, Button, Row, Col,
} from 'react-bootstrap';
import { PRODUCTS } from './config/constant';

function Order() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="order">
      <div className="action-buttons">
        <Button onClick={handleShow}>Order Online Now</Button>
        <div>
          <a className="as-button" href="https://rushportal.services/" target="_blank" rel="noreferrer">Client Portal</a>
        </div>
      </div>
      <div>
        Call
        {' '}
        <a href="tel:5164164889">516.416.4889</a>
      </div>
      <Modal show={show} onHide={handleClose} size="lg" className="order-modal">
        <Modal.Header closeButton>
          <Modal.Title>Order</Modal.Title>
        </Modal.Header>
        <form action="https://usebasin.com/f/f284ff1d2a90" method="POST">
          <Modal.Body>
            <div>
              <h3>Your Info</h3>
              <label htmlFor="email">
                Your Email
                <input type="email" id="email" name="email" required />
              </label>
              <label htmlFor="name">
                Your Name
                <input type="text" id="name" name="name" required />
              </label>
              <label htmlFor="company">
                Your Company Name
                <input type="text" id="company" name="company" required />
              </label>
              <h3>Property Info</h3>
              <label htmlFor="title-no">
                Title no
                <input type="text" id="title-no" name="title-no" required />
              </label>
              <label htmlFor="owner">
                Owner
                <input type="text" id="owner" name="owner" required />
              </label>
              <Row>
                <Col>
                  <label htmlFor="house-no">
                    House no
                    <input type="text" id="house-no" name="house-no" required />
                  </label>
                </Col>
                <Col>
                  <label htmlFor="street">
                    Street name
                    <input type="text" id="street" name="street" required />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label htmlFor="locale">
                    Town\Locality
                    <input type="text" id="locale" name="locale" required />
                  </label>
                </Col>
                <Col>
                  <label htmlFor="county">
                    County
                    <input type="text" id="county" name="county" required />
                  </label>
                </Col>
                <Col>
                  <label htmlFor="district">
                    District
                    <input type="text" id="district" name="district" required />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label htmlFor="section">
                    Section
                    <input type="text" id="section" name="section" required />
                  </label>
                </Col>
                <Col>
                  <label htmlFor="block">
                    Block
                    <input type="text" id="block" name="block" required />
                  </label>
                </Col>
                <Col>
                  <label htmlFor="lot">
                    Lot
                    <input type="text" id="lot" name="lot" required />
                  </label>
                </Col>
                <Col>
                  <label htmlFor="more-lots">
                    Additional lots
                    <input type="text" id="more-lots" name="more-lots" required />
                  </label>
                </Col>
              </Row>
              {Object.keys(PRODUCTS).map((category, c) => (
                <div className="order-group" key={c}>
                  <h3>{category}</h3>
                  <Row>
                    {Object.keys(PRODUCTS[category]).sort((a, b) => {
                      if (PRODUCTS[category][a] > PRODUCTS[category][b]) return 1;
                      if (PRODUCTS[category][a] < PRODUCTS[category][b]) return -1;
                      return 0;
                    }).map((product, i) => (
                      <Col lg={4} key={i}>
                        <div className="checkbox-wrapper">
                          <label htmlFor={product}>
                            <input type="checkbox" id={product} name={product} value={`${product}: ${PRODUCTS[category][product]}`} />
                            {PRODUCTS[category][product]}
                          </label>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
              <input type="hidden" name="message2" id="message2" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Send Order
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default Order;
